import React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import { Loading, useDataProvider } from 'react-admin';
import withWidth from '@material-ui/core/withWidth';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255)',
  },
  pagination: {
    width: '100%',
  },
  image: {
    cursor: 'pointer'
  },
  selected: {
    border: '4px solid #1a72e8',
  }
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(0),
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      maxWidth: 350,
      width: '100%',
    },
  },
  svgColor: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
  title: {
    flex: '1 1 100%',
  },
}));

const getColsForWidth = (width) => {
  if (width === 'xs') return 2;
  if (width === 'sm') return 3;
  if (width === 'md') return 3;
  if (width === 'lg') return 5;
  return 6;
};

const GridToolbar = (props) => {
  const classes = useToolbarStyles();
  const { handlerOnChangeSearch, search } = props;
  return (
    <Toolbar className={classes.root} >
      <TextField id="search" label="Buscar"
        value={search}
        variant="filled"
        onChange={event => {
          const { value } = event.target;
          handlerOnChangeSearch(value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <SearchIcon className={classes.svgColor} />
            </InputAdornment>
          )
        }}
      />
    </Toolbar>
  );
};

const GridListMedia = ({media, handleListItemDoubleClick, handleListItemClick, width, ...props }) => {
  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState({});
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const dataProvider = useDataProvider();
  const [listImage, setListImage] = useState([]);
  const [totalImage, setTotalImage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [info , setInfo] = React.useState([]);

  useEffect(() => {
    getListImage();
  }, [page, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timeOutId = setTimeout(() => getListImage(), 1000);
    return () => clearTimeout(timeOutId);
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  const getListImage = () => {
    const filter = { search: search };

    dataProvider.getList('medias', { pagination: { page: page + 1, perPage: rowsPerPage }, sort: { field: "created_at", order: "DESC" }, filter: filter })
      .then((data) => {
        setListImage(data.data);
        setTotalImage(data.total);
      })
      .catch(error => {
        setListImage([]);
      })
      .finally(() => setLoading(false));
  }

  const handleClick = (media) => {
    handleListItemClick(media)
    setSelectedImage(media);
  };

  const handleDoubleClick = (media) => {
    handleListItemDoubleClick(media);
  };

  const handlerOnChangeSearch = (value) => {
    setSearch(value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  function onImgLoad({target:img},id) {
    let value = {
        id : id,
        dimension : `Tamaño: ${img.naturalWidth} x ${img.naturalHeight} alto`, 
        };
    let arr = info;
    arr.push(value);
    setInfo(arr);          
}
function getValue (id,data){
    return info.find(elem => elem.id === id)?.dimension || data.alt_text;
}

  return (
    loading ? <Loading /> :
      <div className={classes.root}>
        <GridToolbar search={search} handlerOnChangeSearch={handlerOnChangeSearch} />
        <GridList cellHeight={180} cols={getColsForWidth(width)} className={classes.gridList}>
          {listImage.map((media, index) => (
            <GridListTile
              key={index}
              className={`${classes.image} ${media.id === selectedImage.id ? classes.selected : ''}`}
              onClick={(e) => {
                handleClick(media)
              }}
              onDoubleClick={(e) => {
                handleDoubleClick(media)
              }}>
              <img src={media.media_url}  onLoad={(e) =>{ onImgLoad(e,media.id)}} />
              <GridListTileBar
                title={media.original_name ? media.original_name : ''}
                subtitle={ getValue(media.id,media) }
                actionIcon={
                  media.id === selectedImage.id ? (
                    <IconButton aria-label={`seleccionada`} className={classes.icon}>
                      <CheckCircleIcon />
                    </IconButton>)
                    : <></>
                }
              />
            </GridListTile>
          ))}
        </GridList>

        <TablePagination
          className={classes.pagination}
          labelRowsPerPage={'Imágenes por pág:'}
          rowsPerPageOptions={[20, 40, 60]}
          component="div"
          count={totalImage}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
  );
}
export default withWidth()(GridListMedia);