import { Admin, Resource } from "react-admin";
import adminProps from "./config/admin";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import users from './resources/users';
import medias from './resources/medias';
import sizes from './resources/sizes';
import orders from './resources/orders';
import samples from './resources/samples';
import materials from './resources/materials';
import { Dashboard } from './views/dashboard/dashboard';
import englishMessages from 'ra-language-english'; // install the package

const App = () => {
  const i18nProvider = polyglotI18nProvider(() => englishMessages, "en", {
    allowMissing: true,
  }); 
  return (
    <Admin {...adminProps} i18nProvider={i18nProvider} dashboard={Dashboard}>
      <Resource name='users' {...users} />
      <Resource name='medias' {...medias} />
      <Resource name='orders' {...orders} />
      <Resource name='sizes' {...sizes} />
      <Resource name='materials' {...materials} />
      <Resource name='samples' {...samples} />
    </Admin>
  );
};

export default App;
