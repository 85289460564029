import {
    List,
    Datagrid,
    TextField,
    EditButton,
    BooleanField
} from "react-admin";
import { MaterialFilter } from "./MaterialFilter";
import { ListActions } from "../../../components/ListActions";

export const MaterialList = props => {
    return (
        <List
            {...props}
            title='Materials list'
            perPage={10}
            sort={{ field: "id", Material: "ASC" }}
            filters={<MaterialFilter />}
            actions={<ListActions labelButton="Create" />}
            bulkActionButtons={false}
        >
            <Datagrid rowClick={"show"}>
                <TextField source="id" />
                <TextField source="name" label="Name" />
                <TextField source="description" label="Description" />
                <BooleanField source="active" label="active"  />
                <EditButton />
            </Datagrid>
        </List>
    );
};
