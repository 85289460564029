import { fetchUtils } from 'react-admin';

const httpClient = fetchUtils.fetchJson;

export const authProvider = {
    // called when the user attempts to log in
    login: ({ username, password }) => {
        const body = {
            email: username,
            password,
            remember_me:1
        }
        const url = process.env.REACT_APP_API_HOST_ADMIN + '/auth/login';
        return httpClient(url, {
            method: 'POST',
            body: JSON.stringify(body),
        }).then(({ json }) => {
            localStorage.setItem('token', json.access_token);
        }).catch(err => {
            throw new Error(err.statusText);
        })
    },
    // called when the user clicks on the logout button
    logout: () => {
        const token = localStorage.getItem('token');
        const options = {
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            })
        }
        const url = process.env.REACT_APP_API_HOST_ADMIN + '/auth/logout'
        return httpClient(url, options)
            .then(data => {
                localStorage.removeItem('token');
            })
            .catch(err => {
                console.log(err)
            });
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};
