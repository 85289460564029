import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    BooleanField,
    SelectField,
    DateField,
    EditButton,
    DeleteButton,
    NumberField,
} from "react-admin";
import { OrderFilter } from "./OrderFilter";
import { ListActions } from "../../../components/ListActions";
import { choices, choices_payment} from "../choices";

export const OrderList = props => {
   
    return (
        <List
            {...props}
            title='Orders list'
            perPage={10}
            sort={{ field: "id", order: "ASC" }}
            filters={<OrderFilter />}
            actions={<ListActions labelButton="Create order" />}
            bulkActionButtons={false}
        >
            <Datagrid rowClick={"show"}>
                <TextField source="id" />
                <ReferenceField source="media_id" reference="medias" link="show">
                    <TextField source="id" label='Media' />
                </ReferenceField>
                <TextField source="email" label="Email" />
                <SelectField source="status" label="Status" choices={choices} />
                <ReferenceField source="material" reference="materials" link="show">
                    <TextField source="name" label='Material' />
                </ReferenceField>
                <ReferenceField source="size" reference="sizes" link="show">
                    <TextField source="name" label='Size' />
                </ReferenceField>
                <SelectField source="orientation" label="Orientation" choices={[{id:'landscape', name:"Landscape"}, {id: 'portrait',name:"Portrait"}]} optionText="name" optionValue="id"/>
                <NumberField source="quantity" label='Quantity' />
                <BooleanField source="printed" label="Printed" valueLabelTrue="Yes" valueLabelFalse="-"/>
                <BooleanField source="mounted" label="Mounted" valueLabelTrue="Yes" valueLabelFalse="-"/>
                <SelectField source="payment_status" label="Payment_status" choices={choices_payment} />
                <DateField source="created_at" label="Upload date" />
                <EditButton/>
                <DeleteButton/>
            </Datagrid>
        </List>
    );
};
