import {
    SimpleForm,
    Create,
    TextInput,
    required,
    ImageInput,
    ImageField,
    BooleanInput
} from "react-admin";
import { validateFilesize, bytesToSize } from "../../../utils/validations";
export const MaterialCreate = props => {
    const maxSizePhoto = 5242880;
    const validatePhoto = [validateFilesize(maxSizePhoto, 'File size cannot exceed %{maxSize}')];
    return (
        <Create
            {...props}
            title='Material create'
        >
            <SimpleForm
                redirect='list'
                variant='standard'
                margin='dense'
            >
                <TextInput source="name" label="Name" validate={[required()]} />
                <TextInput source="description" label="Description" />
                <BooleanInput source="active" label="Active" defaultValue={true} />
                <ImageInput
                    source="picture"
                    label="Imagen"
                    accept="image/*"
                    maxSize={maxSizePhoto}
                    validate={[validatePhoto, required()]}
                    helperText={`Max size ${bytesToSize(maxSizePhoto)}`}
                >
                    <ImageField source="picture_prev" title="Upload Preview" />
                </ImageInput>
            </SimpleForm>
        </Create>
    );
};
