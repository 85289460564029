import {
    SimpleForm,
    ImageInput,
    ImageField,
    Create,
    required,
} from "react-admin";
import { validateFilesize, bytesToSize } from "../../../utils/validations";
const maxSizePhoto = 5242880;
const validatePhoto = [validateFilesize(maxSizePhoto, 'File size cannot exceed %{maxSize}')];

export const MediaCreate = props => {
    return (
        <Create
            {...props}
            title='Upload Image'
        >
            <SimpleForm
                redirect='list'
                variant='standard'
                margin='dense'
            >
                <ImageInput
                    source="picture"
                    label="Imagen"
                    accept="image/*"
                    maxSize={maxSizePhoto}
                    validate={[validatePhoto, required()]}
                    helperText={`Max size ${bytesToSize(maxSizePhoto)}`}
                >
                    <ImageField source="picture_prev" title="Upload Preview" />
                </ImageInput>
            </SimpleForm>
        </Create>
    );
};
