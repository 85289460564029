import { fetchUtils } from 'react-admin';

export const apiUrl = process.env.REACT_APP_API_HOST_ADMIN;


const buidRequestOptions = (options) => {
    const token = localStorage.getItem('token');
    const customHeaders = options.headers ? options.headers : {},
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        optionsHeaders = { ...headers, ...customHeaders };

    options.headers = optionsHeaders;
    options.mode = 'cors'

    return options
}

export const httpClient = (url, options = {}) => {
    const token = localStorage.getItem('token');
    if (!options.headers) {
        options.headers = new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        });
    }

    return fetchUtils.fetchJson(url, options);
}

export const sendAsFormData = (method, resource, params) => {
    const formData = new FormData();
    for (const name in params.data) {
        formData.append(name, params.data[name] ? params.data[name] : '');
    }

    if (resource === 'medias') {
            formData.set('file', params.data.picture.rawFile)
        // manually set the input file
    }
    if (resource === 'samples') {
        if (params.data?.picture?.rawFile){
            formData.set('file', params.data.picture.rawFile);
        }
    }

    if (resource === 'materials') {
        if (params.data?.picture?.rawFile){
            formData.set('file', params.data.picture.rawFile);
        }
        formData.set('active', params.data.active == 'true' || params.data.active == true ? true : false);
    }


    if (method === 'PUT') {
        formData.append('_method', 'PUT')
        resource = `${resource}/${params.id}`;
        method = 'POST';
    }

    const options = buidRequestOptions({
        ...params,
        method: method,
        body: formData,
    })
    delete options['data'];
    delete options.headers['Content-Type'];

    return fetch(`${apiUrl}/${resource}`, options)
        .then(res => res.json())
        .then(json => ({
            data: { ...params.data, id: json.id, response: json },
        }))
};
