/* eslint-disable import/no-anonymous-default-export */
import { SampleList } from './list/SampleList';
import { SampleCreate } from './create/SampleCreate';
import { SampleEdit } from './edit/SampleEdit';
import { SampleShow } from './show/SampleShow';
export default {
    list: SampleList,
    create: SampleCreate,
    show: SampleShow,
    edit: SampleEdit
};