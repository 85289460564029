export const bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
 }

export const validateFilesize = (maxSize, message) => {
    if (message === void 0) { 
        message = 'ra.validation.maxFileSize'; 
    }
    return (value, allValues) => {
        const fileID = Object.keys(allValues)[0],
            file = document.getElementById(fileID)

        let size = 0
        if (file && file.files) {
            size = file.files[0].size;
        }
        
        if (size && size > maxSize) {
            return {
                message,
                args: { maxSize: bytesToSize(maxSize) }
            };
        }
        return undefined;
    };
}