import {
    SimpleForm,
    Create,
    TextInput,
    required,
    NumberInput,
    BooleanInput
} from "react-admin";
import { InputAdornment } from "@material-ui/core";

export const SizeCreate = props => {

    return (
        <Create
            {...props}
            title='Size create'
        >
            <SimpleForm
                redirect='list'
                variant='standard'
                margin='dense'
            >
                <TextInput source="name" label="Name" validate={[required()]} />
                <NumberInput source="width" label="Width"  validate={[required()]}/>
                <NumberInput source="height" label="Height"  validate={[required()]}/>
                <NumberInput source="price" label="Price" validate={[required()]} InputProps={{
                    startAdornment: (<InputAdornment position='start'>$</InputAdornment>)
                }} />
                <NumberInput source="price_discount" label="Price with discount" defaultValue={0} />
                <BooleanInput source="discount_active" label="Discount active" defaultValue={false} />
                <BooleanInput source="active" label="Active" defaultValue={true} />
            </SimpleForm>
        </Create>
    );
};
