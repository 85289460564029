import { ActionsBack } from "../../../components/ActionsBack";
import {
    SimpleForm,
    Edit,
    required,
    TextInput,
    BooleanInput,
    ImageInput,
    ImageField,
    FormDataConsumer
} from "react-admin";
import { validateFilesize, bytesToSize } from "../../../utils/validations";
export const MaterialEdit = props => {
    const maxSizePhoto = 5242880;
    const validatePhoto = [validateFilesize(maxSizePhoto, 'File size cannot exceed %{maxSize}')];

    return (
        <Edit
            {...props}
            title={'Edit Material'}
            actions={<ActionsBack/>}
        >
            <SimpleForm
                redirect='list'
                variant='standard'
                margin='dense'
            >
                <TextInput source="name" label="Name" validate={[required()]} />
                <TextInput source="description" label="Description" />
                <BooleanInput source="active" label="Active" defaultValue={true} />
                <FormDataConsumer>
                    {({ formData }) => (
                        formData.image && (
                            <img
                                src={formData.image}
                                alt="Current Image"
                            />
                        ))}
                </FormDataConsumer>
                <ImageInput
                    source="picture"
                    label="Image"
                    accept="image/*"
                    maxSize={maxSizePhoto}
                    validate={[validatePhoto]}
                    helperText={`Max size ${bytesToSize(maxSizePhoto)}`}
                >
                    <ImageField source="picture_prev" title="Upload Preview" />
                </ImageInput>

               
            </SimpleForm>
        </Edit>
    );
};
