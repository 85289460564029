/* eslint-disable import/no-anonymous-default-export */
import { MediaList } from './list/MediaList';
import { MediaCreate } from './create/MediaCreate';
import { MediaEdit } from './edit/MediaEdit';
import { MediaShow } from './show/MediaShow';
export default {
    list: MediaList,
    create: MediaCreate,
    show: MediaShow,
    edit: MediaEdit
};