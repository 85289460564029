import * as React from 'react';
import { createElement } from 'react';
import { Card, Box, Typography, Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
    card: {
        minHeight: 52,
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        '& a': {
            textDecoration: 'none',
        },
    },
    main: (props) => ({
        overflow: 'inherit',
        padding: '16px',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .icon': {
            color: '#5C7B34',
        },
    }),
    title: {
        color: '#818181',
        fontSize: '1.2em',
    },
    subtitleWarning:{
        color: '#E59800',
        '&:hover': {
            color: '#E59800',
        }
    },
    subtitleNormal:{
        color: '#343434',
        '&:hover': {
            color: '#343434',
        }
    },
    link:{
        color: '#5C7B34',
        fontWeight: '500',
        fontSize:'1.2em',
        '&:hover': {
            color: '#5C7B34',
        }
    }
}));

const CardWithIcon = ({ icon, title, subtitle, to, buttonText, buttonAction, color, decoration, ...props }) => {
    const classes = useStyles(props);
    return (
        <Card className={classes.card}>
            <Link to={to}>
                <div className={classes.main} >
                    <Box p="0.5em" className="icon" display="flex" alignSelf="flex-start" >
                        {createElement(icon, { style:{ fontSize: '3.5em' } })}
                    </Box>
                    <Box textAlign="right"  >
                        <Typography
                            className={classes.title}
                            color="textSecondary"
                        >
                            {title}
                        </Typography>
                        <Typography variant="h5" component="h2" sx={{ fontSize: '1.6em',fontWeight: '600',marginBottom: '0.4em'}} className={color ? classes.subtitleWarning : classes.subtitleNormal}>
                           {decoration ? decoration : ''}{subtitle ? subtitle : 0}
                        </Typography>
                        <span onClick={(e)=>{e.preventDefault()}} className={classes.link}>{buttonText}</span>
                    </Box>
                </div>
            </Link>
        </Card>
    );
};

export default CardWithIcon;
