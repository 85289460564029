import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    ImageField,
    BooleanField,
} from 'react-admin';
import { ActionsBackEdit } from '../../../components/ActionsBackEdit';
export const MaterialShow = ({ ...props }) => {
    return (
        <Show {...props} actions={<ActionsBackEdit/>}>
            <SimpleShowLayout >
            <TextField source="id" />
                <TextField source="name" label="Name" />
                <TextField source="description" label="Description" />
                <BooleanField source="active" label="Active"  valueLabelTrue="Active" valueLabelFalse='-'/>
                <ImageField source="image" label="Image" />
            </SimpleShowLayout>
        </Show>
    )
}