import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
    DateField,
    UrlField,
    ImageField
} from 'react-admin';

import { ActionsBackEdit } from '../../../components/ActionsBackEdit';

export const MediaShow = ({ ...props }) => {

    return (
        <Show {...props} actions={<ActionsBackEdit/>}>
            <SimpleShowLayout >
                <ReferenceField source="user_id" reference="users">
                    <TextField source="username" label='Users' />
                </ReferenceField>
               <TextField source="original_name" label='Filename' />
                <DateField source="created_at" label="Upload date" />
                <UrlField source="media_url" label="upload link" target="_blank"/>
                <ImageField source="media_url" label="Upload Preview" />
            </SimpleShowLayout>
        </Show>
    )
}