import {
    SimpleForm,
    ImageInput,
    ImageField,
    Create,
    required,
    ReferenceInput,
    AutocompleteInput,
    SelectInput
} from "react-admin";
import { validateFilesize, bytesToSize } from "../../../utils/validations";
const maxSizePhoto = 5242880;
const validatePhoto = [validateFilesize(maxSizePhoto, 'File size cannot exceed %{maxSize}')];

export const SampleCreate = props => {
    return (
        <Create
            {...props}
            title='Upload Image'
        >
            <SimpleForm
                redirect='list'
                variant='standard'
                margin='dense'
            >
                <ReferenceInput
                    source="size_id"
                    reference="sizes"
                    label='Size'
                    format={(v) => (!v ? null : v)}
                    filterToQuery={(searchText) => ({ search: searchText })}>
                    <AutocompleteInput source="size_id" optionText={choices => `${choices?.name} - $${choices?.discount_active ? choices?.price_discount : choices?.price} ${choices?.discount_active ? 'Sale' : ''}`} optionValue='id' />
                </ReferenceInput>
                <ReferenceInput
                    source="material_id"
                    reference="materials"
                    label='Material'
                    format={(v) => (!v ? null : v)}
                    filterToQuery={(searchText) => ({ search: searchText })}>
                    <AutocompleteInput source="material_id" optionText="name" optionValue='id' />
                </ReferenceInput>
                <SelectInput 
                    source="orientation" 
                    defaultValue={'landscape'}
                    choices={[{id:'landscape',name:"Landscape"},{id:'portrait',name:'Portrait'},{id:'square', name:"Square"}]} 
                    optionValue="id" 
                    optionText="name"
                    label="Orientation"  
                    validate={[required()]}
                />
                <ImageInput
                    source="picture"
                    label="Imagen"
                    accept="image/*"
                    maxSize={maxSizePhoto}
                    validate={[validatePhoto, required()]}
                    helperText={`Max size ${bytesToSize(maxSizePhoto)}`}
                >
                    <ImageField source="picture_prev" title="Upload Preview" />
                </ImageInput>
            </SimpleForm>
        </Create>
    );
};
