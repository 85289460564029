import {
    SimpleForm,
    ImageInput,
    ImageField,
    Edit,
    required,
    FormDataConsumer
} from "react-admin";
import { ActionsBack } from "../../../components/ActionsBack";
import { validateFilesize, bytesToSize } from "../../../utils/validations";
const maxSizePhoto = 5242880
const validatePhoto = [validateFilesize(maxSizePhoto, 'File size cannot exceed %{maxSize}')];
export const MediaEdit = props => {
    return (
        <Edit
            {...props}
            title={'Edit Image'}
            actions={<ActionsBack/>}
        >
            <SimpleForm
                redirect='list'
                variant='standard'
                margin='dense'
            >
                <FormDataConsumer>
                    {({ formData }) => (
                        formData.image && (
                            <img
                                src={formData.image}
                                alt="Current Image"
                            />
                        ))}
                </FormDataConsumer>
                <ImageInput
                    source="picture"
                    label="Image"
                    accept="image/*"
                    maxSize={maxSizePhoto}
                    validate={[validatePhoto, required()]}
                    helperText={`Max size ${bytesToSize(maxSizePhoto)}`}
                >
                    <ImageField source="picture_prev" title="Upload Preview" />
                </ImageInput>

            </SimpleForm>
        </Edit>
    );
};
