import { useState } from "react";
import { MenuItemLink } from "react-admin";
import { useSelector } from "react-redux";
import { Box } from '@material-ui/core';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import WallpaperIcon from '@material-ui/icons/Wallpaper';

export const Menu = ({ onMenuClick, logout, dense = false }) => {
  const [state, setState] = useState({
    usersMenu: false,
    dealsMenu: false,
    moneyboxesMenu: false,
  });
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  useSelector(state => state.theme);

  return (
    <Box mt={3} >
      <MenuItemLink
        to={`/`}
        primaryText={"Home"}
        leftIcon={<HomeRoundedIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
        exact

      />
      <MenuItemLink
        to={`/medias`}
        primaryText={"Images"}
        leftIcon={<PermMediaIcon/>}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        role="menu"
        dense={dense}
      />
      <MenuItemLink
        to={`/orders`}
        primaryText={"Orders"}
        leftIcon={<AssignmentIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        role="menu"
        dense={dense}
      />
       <MenuItemLink
        to={`/sizes`}
        primaryText={"Sizes"}
        leftIcon={<AspectRatioIcon  />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        role="menu"
        dense={dense}
      />
       <MenuItemLink
        to={`/materials`}
        primaryText={"Materials"}
        leftIcon={<AssignmentIndIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        role="menu"
        dense={dense}
      />
      <MenuItemLink
        to={`/samples`}
        primaryText={"Samples"}
        leftIcon={<WallpaperIcon/>}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        role="menu"
        dense={dense}
      />

      {/* USUARIOS */}
        <MenuItemLink
          to={`/users`}
          primaryText={"Users"}
          leftIcon={<AccountCircleIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          role="submenu"
          dense={dense}
        />
      {logout}
    </Box>
  );
};
