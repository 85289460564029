import {
    List,
    Datagrid,
    TextField,
    DateField,
    DeleteButton,
    ReferenceField,
    SelectField,
    EditButton,
} from "react-admin";
import { SampleFilter } from "./SampleFilter";
import { ListActions } from "../../../components/ListActions";

export const SampleList = props => {

    return (
        <List
            {...props}
            title='Picture list'
            perPage={10}
            sort={{ field: "id", order: "ASC" }}
            filters={<SampleFilter />}
            actions={<ListActions labelButton="Upload Image" />}
            bulkActionButtons={false}
        >
            <Datagrid rowClick={"show"}>
                <ReferenceField source="size_id" reference="sizes" link="show">
                    <TextField source="name" label='Size' />
                </ReferenceField>
                <ReferenceField source="material_id" reference="materials" link="show">
                    <TextField source="name" label='Material' />
                </ReferenceField>
                <SelectField 
                     source="orientation" 
                     choices={[{id:'landscape',name:"Landscape"},{id:'portrait',name:'Portrait'},{id:'square', name:"Square"}]} 
                     optionValue="id" 
                     optionText="name"
                     label="Orientation"  
                />
                <DateField source="created_at" label="Upload date" />
                <EditButton/>
                <DeleteButton/>
            </Datagrid>
        </List>
    );
};
