import { useState, useEffect } from "react";
import {
    SimpleForm,
    Create,
    ReferenceInput,
    TextInput,
    BooleanInput,
    FormDataConsumer,
    SelectInput,
    TextField,
    required,
    AutocompleteInput,
    NumberInput
} from "react-admin";
import { Box, Button } from '@material-ui/core'
import ImagePicker from "../../../components/pickers/ImagePicker";
import { choices, choices_payment } from "../choices";
import { CustomTotal } from "../CustomTotal";

export const OrderCreate = props => {
    const [show, setShow] = useState(false);
    const handleClick = () => {
        setShow(!show);
    }
    return (
        <Create
            {...props}
            title='Order create'
        >
            <SimpleForm
                redirect='list'
                variant='standard'
                margin='dense'
            >
                <FormDataConsumer>{({ formData, ...rest }) => {
                    return (
                        <Box width="100%" display="flex" justifyContent="start" >
                            <Box display="flex" flexDirection="column" maxWidth="600px" minWidth="300px">
                                <ReferenceInput
                                    source="user_id"
                                    reference="users"
                                    label='Username'
                                    allowEmpty
                                    allowNull
                                    format={(v) => (!v ? null : v)}
                                    filterToQuery={(searchText) => ({ search: searchText })}>
                                    <AutocompleteInput source="user_id" optionText="username" optionValue='id' />
                                </ReferenceInput>
                                <CustomTotal source="total" />
                                {!formData.user_id &&
                                    <Box display="flex" flexDirection="column">
                                        <TextInput source="first_name" label="First name" />
                                        <TextInput source="last_name" label="Last name" />
                                        <TextInput source="phone" label="Phone number" />
                                        <TextInput source="email" label="Email" validate={required()} />
                                    </Box>}
                                <Button onClick={handleClick} >Select Image</Button>
                                <ImagePicker show={show} setShow={setShow} sourceId="media_id" sourceImage="media_url" />
                                <TextField source="media_id" label="Image Selected" />
                                <ReferenceInput
                                    source="material"
                                    reference="materials"
                                    label='Material'
                                    format={(v) => (!v ? null : v)}
                                    filterToQuery={(searchText) => ({ search: searchText })}>
                                    <AutocompleteInput source="material" optionText="name" optionValue='id' />
                                </ReferenceInput>
                                <ReferenceInput
                                    source="size"
                                    reference="sizes"
                                    label='Size'
                                    format={(v) => (!v ? null : v)}
                                    filterToQuery={(searchText) => ({ search: searchText })}>
                                    <AutocompleteInput source="size" optionText={choices => `${choices?.name} - $${choices?.discount_active ? choices?.price_discount : choices?.price} ${choices?.discount_active ? 'Sale' : ''}`} optionValue='id' />
                                </ReferenceInput>
                                <SelectInput source="orientation" label="Orientation" choices={[{id:'landscape', name:"Landscape"}, {id: 'portrait',name:"Portrait"}]} optionText="name" optionValue="id"/>
                                <NumberInput source="quantity" label="Quantity" validate={required()} defaultValue={1} />
                                <SelectInput source="status" choices={choices} defaultValue='in_queue' />
                                <SelectInput source="payment_status" choices={choices_payment} defaultValue='none' />
                                <BooleanInput source="printed" label="Printed" />
                                <BooleanInput source="mounted" label="Mounted" />
                            </Box>
                            <Box display="flex" ml="1em">
                                {formData?.media_url && <img src={formData?.media_url} alt="Image Selected" style={{ width: '400px', height: '400px' }} />}
                            </Box>
                        </Box>
                    )
                }}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
};
