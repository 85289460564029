import { cloneElement } from 'react';
import {
    TopToolbar,
    CreateButton
  } from "react-admin";

  export const ListActions = (props) => (
    <TopToolbar style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
        {cloneElement(props.filters, { context: 'button' })}
        <CreateButton variant="contained" label={props.labelButton}/>
    </TopToolbar>
);
  