/* eslint-disable import/no-anonymous-default-export */
import { MaterialList } from './list/MaterialList';
import { MaterialCreate } from './create/MaterialCreate';
import { MaterialEdit } from './edit/MaterialEdit';
import { MaterialShow } from './show/MaterialShow';
export default {
    list: MaterialList,
    create: MaterialCreate,
    show: MaterialShow,
    edit: MaterialEdit
};