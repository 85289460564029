import {
    List,
    Datagrid,
    FunctionField,
    TextField,
  } from "react-admin";
  import { UserFilter } from "./UserFilter";
  import { ListActions } from "../../../components/ListActions";

  export const UserList = props => {
    return (
      <List
        {...props}
        title='User list'
        perPage={10}
        sort={{ field: "first_name", order: "ASC" }}
        filters={<UserFilter />}
        bulkActionButtons={false}
        actions={<ListActions labelButton="New user"/>}
      >
        <Datagrid title='Users' rowClick={"show"}>
          <FunctionField
            label='First name and last name'
            render={record => `${record.first_name} ${record.last_name}`}
          />
          <TextField source='email' label='Email' />
          <TextField source='phone' label='Phone' />
        </Datagrid>
      </List>
    );
  };
  