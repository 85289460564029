/* eslint-disable import/no-anonymous-default-export */
import { OrderList } from './list/OrderList';
import { OrderCreate } from './create/OrderCreate';
import { OrderEdit } from './edit/OrderEdit';
import { OrderShow } from './show/OrderShow';
export default {
    list: OrderList,
    create: OrderCreate,
    show: OrderShow,
    edit: OrderEdit
};