import {
    TopToolbar,
    EditButton
} from 'react-admin';
import BackButton from './BackButton';

export const ActionsBackEdit = ({ basePath , data , resource,props }) => (
    <TopToolbar style={{display: 'flex', justifyContent: 'space-between'}}>
      <BackButton >Back</BackButton>
      <EditButton basePath={basePath}
        record={data}
        resource={resource}
        undoable={false}/>
    </TopToolbar>
  );