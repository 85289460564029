import {
  Filter,
  SearchInput,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  BooleanInput,
  SelectArrayInput
} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';
import { choices, choices_payment} from "../choices";
const useStyles = makeStyles({
  select: {
    minWidth: ' 160px',
    maxWidth: '300px',
    marginTop: '40px',
  }
});

export const OrderFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} variant='outlined'>
      <SearchInput source='search' alwaysOn />
      <DateInput source='created_at' label='Date' />
      <SelectArrayInput
        source='status'
        label='Status'
        variant='outlined'
        className={classes.select}
        choices={choices}
      />
      <SelectArrayInput
        source='payment_status'
        label='Payment Status'
        variant='outlined'
        className={classes.select}
        choices={choices_payment}
      />
      <ReferenceInput filterToQuery={searchText => ({ search: searchText })} source='material' reference='materials' label='Material'>
        <AutocompleteInput
          optionText={choice =>(
            choice?.id // the empty choice is { id: '' }
              ? `${choice?.name}`
              : "")
          }
          source="id"
        />
      </ReferenceInput>
      <ReferenceInput filterToQuery={searchText => ({ search: searchText })} source='size' reference='sizes' label='Size'>
        <AutocompleteInput
          optionText={choice =>(
            choice?.id // the empty choice is { id: '' }
              ? `${choice?.name}`
              : ""
          )}
          source="id"
        />
      </ReferenceInput>
      <BooleanInput source='monthly' label='Last Month' defaultValue={true} />
    </Filter>
  )
}
