import { createTheme } from '@material-ui/core/styles';

const primaryColor = process.env.REACT_APP_PRIMARY_COLOR ? process.env.REACT_APP_PRIMARY_COLOR : 'blue';
export const customTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: '#1fa081',
    },
    error: {
      main: '#FF0000'
    },
    warning: {
      main: '#E59800'
    },
    success: {
      main:'#2AB572'
    },
    grey: {
      main: '#818181'
    },
    greydark:{
      main: '#343434'
    },
    background: {
      main: '#F5F5F5'
    },
    tonalOffset: 0.2,
    contrastThreshold: 3,
  },
  main:{
    background: '#F5F5F5'
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  overrides: {
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        // Name of the rule
        color: '#5C7B34',
      },
    },
    RaLayout:{
      content:{
        backgroundColor: '#F5F5F5',
        padding: '1em !important',
        boxSizing: 'border-box'
      }
    },
    MuiDrawer: {
      paper: {
        '& div:nth-child(1)': {
          position: 'relative',
        }
      }
    },
    RaMenuItemLink: {
      root: {
          borderLeft: '3px solid #fff',
      },
      active: {
          borderLeft: '3px solid #5C7B34',
      },
    },
    MuiFilledInput:{
      underline:{
        '&:after':{
          borderBottomColor: '#5C7B34!important',
        }
      }
    }
  },
});
