import {
    List,
    Datagrid,
    TextField,
    EditButton,
    DeleteButton,
    NumberField,
    BooleanField
} from "react-admin";
import { SizeFilter } from "./SizeFilter";
import { ListActions } from "../../../components/ListActions";
import { InputAdornment } from "@material-ui/core";

export const SizeList = props => {
    return (
        <List
            {...props}
            title='Sizes list'
            perPage={10}
            sort={{ field: "id", Size: "ASC" }}
            filters={<SizeFilter />}
            actions={<ListActions labelButton="Create " />}
            bulkActionButtons={false}
        >
            <Datagrid rowClick={"show"}>
                <TextField source="id" />
                <TextField source="name" label="Name" />
                <NumberField source="width" label="Width"  />
                <NumberField source="height" label="Height"  />
                <NumberField source="price" label="Price" options={{
                    InputProps: {
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>
                    }
                }} />
                <NumberField source="price_discount" label="Price Discount" options={{
                    InputProps: {
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>
                    }
                }} />
                <BooleanField source="discount_active" label="Discount active" />
                <BooleanField source="active" label="Active" />
                <EditButton />
            </Datagrid>
        </List>
    );
};
