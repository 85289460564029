import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import GridListMedia from '../fields/GridListMedia';
import { useField } from 'react-final-form';


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    gridContainer: {
        height: '100%',
    },
    list: {
        width: '100%',
    },
    itemContainer: {
        padding: '20px',
        overflowY: 'auto',
        height: '100%',
    },
    tabs: {
        "& .MuiTab-wrapper": {
            flexDirection: "row",
            '& svg': {
                marginRight: '8px'
            }
        }
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImagePicker({show,setShow,sourceId,sourceImage,props}) {
    const classes = useStyles();
    const [selectedValue, setSelectedValue] = useState();
    const [openImagePicker, setOpenImagePicker] = useState(false);
    const {input: mediadata } = useField(sourceId);
    const {input: mediaurl } = useField(sourceImage);

    const handleClose = () => {
        setOpenImagePicker(false);
        setShow(false);
    };

    const handleSave = () => {
        if (selectedValue) {
            const image = {
                media_id: selectedValue.id,
                media_url: selectedValue.media_url,
            };
            mediadata.onChange(image.media_id);
            mediaurl.onChange(image.media_url);
        }
        handleClose();
    };

    const handleListItemDoubleClick = (value) => {
        if (value) {
            const image = {
                media_id: value.id,
                media_url: value.media_url,
            };
         
            mediadata.onChange(image.media_id);
            mediaurl.onChange(image.media_url);
        }
        handleClose();
    };

    const handleListItemClick = (value) => {
        setSelectedValue(value);
    };

    useEffect(()=>{
        if(show){
            setOpenImagePicker(true);
        }
    },[show])


    return (
        <Dialog fullScreen open={openImagePicker} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Seleccionar imagen
                    </Typography>
                </Toolbar>
            </AppBar>


            <Grid container className={classes.gridContainer}>
                <Grid item xs={12} className={classes.itemContainer}>
                    <GridListMedia media={"image"} handleListItemDoubleClick={handleListItemDoubleClick} handleListItemClick={handleListItemClick} />
                </Grid>
            </Grid>

            <Divider />
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button variant="outlined" color="primary" onClick={handleSave}>
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
}