import React, {
    useState,
    useCallback,
    useEffect,
} from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useVersion, useDataProvider } from 'react-admin';
import Welcome from './Welcome';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CardWithIcon from './CardWithIcon';

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
    box: { marginRight: '1em', flex: 1 },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em', margin: '0.5em 0 0.5em 0' }} />;

export const Dashboard = (props) => {
    const [state, setState] = useState({});
    const dataProvider = useDataProvider();
    const version = useVersion();
    const theme = useTheme();
    const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
    const fetchOrders = useCallback(async () => {

        const { data: ordersmonthly } = await dataProvider.getList(
            'orders',
            {
                filter: { monthly: true , payment_status: ['paid']},
                sort: { field: 'created_at', order: 'DESC' },
                pagination: { page: 1, perPage: 100 },
            }
        );
        const { data: ordersPending, total:pending_total  } = await dataProvider.getList(
            'orders',
            {
                filter: {  payment_status: ['pending'] },
                sort: { field: 'created_at', order: 'DESC' },
                pagination: { page: 1, perPage: 100 },
            }
        );
        const { data: orderspaidPending } = await dataProvider.getList(
                'orders',
                {
                    filter: { status: ['in_queue'], payment_status: ['pending','none'] },
                    sort: { field: 'created_at', order: 'DESC' },
                    pagination: { page: 1, perPage: 100 },
                }
            );
        const monthly_total =  ordersmonthly.reduce(
            (acum,order) =>{
                if (order?.total){
                    acum += Number(order.total);
                    return acum;
                }
                return acum;
            },
            0
        );
        const pending_paid_total =  orderspaidPending.reduce(
            (acum,order) =>{
                if (order?.total){
                    acum += Number(order.total);
                    return acum;
                }
                return acum;
            },
            0
        );

       
        setState(state => ({
            ...state,
            ordersmonthly,
            ordersPending,
            pending_total,
            monthly_total,
            orderspaidPending,
            pending_paid_total,
        }));
    }, [dataProvider]);


    useEffect(() => {
        fetchOrders();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    

    const {
        ordersmonthly,
        ordersPending,
        pending_total,
        monthly_total,
        orderspaidPending,
        pending_paid_total
    } = state;


    return isXSmall ? (
        <div>
            <div style={styles.flexColumn}>
                <Welcome />
                <CardWithIcon 
                    to={{ pathname: "/orders", search: `filter= ${JSON.stringify({ payment_status: 'pending' })}`}}
                    icon={BusinessCenterIcon}
                    title={"Pending orders"}
                    subtitle={pending_total || 0}
                    buttonText="Detail"
                    
                />
                <VerticalSpacer />
                <CardWithIcon
                    to={{ pathname: '/orders', search:  `filter=${JSON.stringify({ status: ['in_queue'], payment_status: ['pending','none']})}`}} 
                    icon={MonetizationOnRoundedIcon}
                    title={'Pending payments'}
                    subtitle={pending_paid_total || 0}
                    decoration={'$'}
                    buttonText="Detail"
                    
                />
                <VerticalSpacer />
                <CardWithIcon
                    to={{ pathname: '/orders', search: `filter=${JSON.stringify({monthly: true , payment_status: ['paid'] })}`}}
                    icon={MonetizationOnRoundedIcon}
                    title={"Last month's payments"}
                    subtitle={monthly_total || 0}
                    decoration={'$'}
                    buttonText="Detail"
                />
                <VerticalSpacer />
            </div>
        </div>
    ) : (
        <div style={styles.flexColumn}>
            <div style={styles.singleCol}>
                <Welcome />
            </div>
            <div style={styles.flex}>
                <div style={styles.box}>
                    <CardWithIcon
                        to={{ pathname: "/orders", search: `filter= ${JSON.stringify({ payment_status: ['pending'] })}`}}
                        icon={BusinessCenterIcon}
                        title={"Pending orders"}
                        subtitle={pending_total || 0}
                        buttonText="Detail"
                      
                    />
                </div>
                <div style={styles.box}>
                    <CardWithIcon
                        to={{ pathname: '/orders', search: `filter= ${JSON.stringify({ status: ['in_queue'], payment_status: ['pending','none'] })}`}} 
                        icon={MonetizationOnRoundedIcon}
                        title={'Pending payments'}
                        subtitle={pending_paid_total || 0}
                        decoration={'$'}
                        buttonText="Detail"
                    />
                </div>
                <div style={styles.box}>
                    <CardWithIcon
                        to={{ pathname: '/orders', search: `filter= ${JSON.stringify({monthly: true , payment_status: ['paid'] })}`}}
                        icon={MonetizationOnRoundedIcon}
                        title={"Last month's payments"}
                        subtitle={monthly_total || 0}
                        decoration={'$'}
                        buttonText="Detail"
                        
                    />
                </div>
            </div>
        </div>
    );
};
