import {
    List,
    Datagrid,
    TextField,
    DateField,
    DeleteButton,
    useNotify,
    useRefresh,
} from "react-admin";
import { MediaFilter } from "./MediaFilter";
import { ListActions } from "../../../components/ListActions";

export const MediaList = props => {
    const notify = useNotify();
    const refresh = useRefresh();

    const onFailure  =(e) =>{
        console.log(e.message);
        notify(`This image has an order with status in queue, please change the status order to proceed`,'info');
        refresh();
    }
    return (
        <List
            {...props}
            title='Picture list'
            perPage={10}
            sort={{ field: "id", order: "ASC" }}
            filters={<MediaFilter />}
            actions={<ListActions labelButton="Upload Image" />}
            bulkActionButtons={false}
        >
            <Datagrid rowClick={"show"}>
                <TextField source="original_name" label='Filename' />
                <DateField source="created_at" label="Upload date" />
            </Datagrid>
        </List>
    );
};
