import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    BooleanField,
    NumberField
} from 'react-admin';
import { InputAdornment } from "@material-ui/core";
import { ActionsBackEdit } from '../../../components/ActionsBackEdit';

export const SizeShow = ({ ...props }) => {
    return (
        <Show {...props} actions={<ActionsBackEdit/>}>
            <SimpleShowLayout >
            <TextField source="id" />
                <TextField source="name" label="Name" options={{
                    InputProps: {
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>
                    }
                }} />
                <NumberField source="width" label="Width"  />
                <NumberField source="height" label="Height"  />
                <NumberField source="price" label="Price" options={{
                    InputProps: {
                        startAdornment: <InputAdornment position='start'>$</InputAdornment>
                    }
                }} />
                <NumberField source="price_discount" label="Price Discount" />
                <BooleanField source="discount_active" label="Discount active"  valueLabelTrue="Active" valueLabelFalse='-'/>
                <BooleanField source="active" label="Active"  valueLabelTrue="Active" valueLabelFalse='-'/>
            </SimpleShowLayout>
        </Show>
    )
}