import {
  SimpleForm,
  TextInput,
  Create,
  required,
} from "react-admin";
import { ActionsBack } from "../../../components/ActionsBack";

export const UserCreate = props => {
  return (
    <Create
      {...props}
      title='New User'
      actions={<ActionsBack />}
    >
      <SimpleForm
        redirect='list'
        variant='standard'
        margin='dense'
      >
        <TextInput
          source='first_name'
          label='First name'
          variant='outlined'
          validate={required()}
        />
        <TextInput source='last_name' label='Last name' variant='outlined' />
        <TextInput
          source='email'
          label='Emails'
          type="email"
          variant='outlined'
          validate={[required()]}
        />
        <TextInput
          source='phone'
          label='Phone'
          type="phone"
          variant='outlined'
          validate={[required()]}
        />
        <TextInput
          source='password'
          label='Password'
          type="password"
          variant='outlined'
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
};
