import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
    DateField,
    UrlField,
    ImageField,
    SelectField
} from 'react-admin';

import { ActionsBackEdit } from '../../../components/ActionsBackEdit';

export const SampleShow = ({ ...props }) => {

    return (
        <Show {...props} actions={<ActionsBackEdit/>}>
            <SimpleShowLayout >
                <ReferenceField source="size_id" reference="sizes" link="show">
                    <TextField source="name" label='Size' />
                </ReferenceField>
                <ReferenceField source="material_id" reference="materials" link="show">
                    <TextField source="name" label='Material' />
                </ReferenceField>
                <SelectField 
                     source="orientation" 
                     choices={[{id:'landscape',name:"Landscape"},{id:'portrait',name:'Portrait'},{id:'square', name:"Square"}]} 
                     optionValue="id" 
                     optionText="name"
                     label="Orientation"  
                />
                <DateField source="created_at" label="Upload date" />
                <UrlField source="image" label="link" target="_blank"/>
                <ImageField source="image" label="Upload Preview" />
            </SimpleShowLayout>
        </Show>
    )
}