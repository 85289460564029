import { useEffect } from "react";
import { NumberInput } from "react-admin";
import { useField, useFormState } from "react-final-form";
import { useSelector} from 'react-redux';

export const CustomTotal = ({ source }) => {
    const { input: price } = useField(source);
    const { values } = useFormState();
    const sizes = useSelector(state => state.admin.resources.sizes.data);
    const findSize = (id) => {
        if (sizes && id) {
            return Object.values(sizes).find(item => item?.id === id);
        }
        return null;
    }

    useEffect(() => {
        if (values?.quantity && values?.size) {
            let result = 0;
            let size = findSize(values.size);
            if (size) {
                if (size.discount_active) {
                    result = size.price_discount;
                } else {
                    result = size.price;
                }
                if (values?.mounted) {
                    result = result * size.price_mounted;
                }
                result = result * values.quantity;
                price.onChange(result);
            }else{
                price.onChange(0);
            }
        }else{
            price.onChange(0);
        }

    }, [values?.quantity, values?.size, values?.mounted])
    return (
        <NumberInput source={source} label="Total" />
    )
}