export  const choices = [
    { id: 'in_queue', name: 'In queue' },
    { id: 'complete', name: 'Complete' },
    { id: 'cancelled', name: 'Cancelled' }
];
export const choices_payment = [
    { id: 'none', name: 'None' },
    { id: 'pending', name: 'Pending' },
    { id: 'paid', name: 'Paid' },
    { id: 'cancelled', name: 'Cancelled' },
    { id: 'rejected', name:'Rejected'}
];
