import * as React from 'react';
import { Box, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@material-ui/core/styles';

const useStyles = makeStyles(({
    root: {
        backgroundColor: '#5C7B34',
        color: '#fff',
        padding: 20,
        margin: '16px 0',
    },
    actions: {
        padding: 0,
        flexWrap: 'wrap',
        '& a': {
            marginTop: '16px',
        },
    },
}));

  const CustomCard = styled(Card)(({ theme }) => ({
    color: 'white',
    backgroundColor: '#5C7B34',
  }));

const Welcome = () => {
    const classes = useStyles();
    return (
        <CustomCard className={classes.root}>
            <Box display="flex">
                <Box flex="1">
                    <Typography variant="h4" component="h1" gutterBottom>
                        Welcome to Whole Images
                    </Typography> 
                </Box>

            </Box>
        </CustomCard>
    );
};

export default Welcome;
