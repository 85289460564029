/* eslint-disable import/no-anonymous-default-export */
import { SizeList } from './list/SizeList';
import { SizeCreate } from './create/SizeCreate';
import { SizeEdit } from './edit/SizeEdit';
import { SizeShow } from './show/SizeShow';
export default {
    list: SizeList,
    create: SizeCreate,
    show: SizeShow,
    edit: SizeEdit
};