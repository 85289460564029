import React, { Fragment } from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
    BooleanField,
    SelectField,
    ImageField,
    NumberField,
    FunctionField,
    Labeled
} from 'react-admin';
import { ActionsBackEdit } from '../../../components/ActionsBackEdit';
import { Box } from '@material-ui/core';

export const OrderShow = ({ ...props }) => {
    const choices = [
        { id: 'in_queue', name: 'In queue' },
        { id: 'complete', name: 'Complete' },
        { id: 'cancelled', name: 'Cancelled' }
    ];
    const choices_payment = [
        { id: 'none', name: 'None' },
        { id: 'pending_payment', name: 'Pending' },
        { id: 'paid', name: 'Paid' },
        { id: 'cancelled', name: 'Cancelled' }
    ];
    return (
        <Show {...props} actions={<ActionsBackEdit />}>
            <SimpleShowLayout >
                {/* <ReferenceField source="user_id" reference="users" link="show">
                    <TextField source="username" label='Users' />
                </ReferenceField> */}
                <Labeled label="Customer">
                    <FunctionField style={{ fontWeight: 600, fontSize: '1.2em' }} render={record => record?.first_name && record?.last_name ? `${record.first_name} ${record.last_name}` : ''} />
                </Labeled>
                <TextField source="total" label="Total" style={{ fontWeight: 600, fontSize: '1.3em' }} />
                <Box display="flex" justifyContent="space-between" maxWidth="70%">
                    <Box display="flex" flexDirection="column">
                        <Box >
                            <Labeled label="Status">
                                <SelectField source="status" choices={choices} />
                            </Labeled>
                        </Box>
                        <Box>
                            <Labeled label='Material'>
                                <ReferenceField source="material" reference="materials" link="show">
                                    <TextField source="name" />
                                </ReferenceField>
                            </Labeled>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <Box>
                            <Labeled label="Payment status">
                                <SelectField source="payment_status" choices={choices_payment} />
                            </Labeled>
                        </Box>
                        <Box >
                            <Labeled label='Size'>
                                <ReferenceField source="size" reference="sizes" link="show">
                                    <TextField source="name" />
                                </ReferenceField>
                            </Labeled>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <Box>
                            <Labeled label="printed">
                                <BooleanField source="printed" valueLabelTrue="Printed" valueLabelFalse="Pending" />
                            </Labeled>
                        </Box>
                        <Box mr="1.5em">
                            <Labeled label='Quantity'>
                                <NumberField source="quantity" />
                            </Labeled>
                        </Box>
                    </Box>
                </Box>
                <Labeled label="Orientation">
                    <SelectField source="orientation" label="Orientation" choices={[{id:'landscape', name:"Landscape"}, {id: 'portrait',name:"Portrait"}]} optionText="name" optionValue="id"/>
                </Labeled>
                <Labeled label="Mounted">
                    <BooleanField source="mounted" valueLabelTrue="Yes" valueLabelFalse="-" />
                </Labeled>
                <ReferenceField source="media_id" reference="medias" link="show" label="Image">
                    <Fragment>
                        <TextField source="media_url" />
                        <ImageField source="media_url" />
                    </Fragment>
                </ReferenceField>
                <TextField source="state" label="State"/>
                <TextField source="city" label="City"/>
                <TextField source="shipping_address" label="Shipping address" />
                <TextField source="zip_code" label="ZIP code"/>
                <TextField source="comments" label="Comments" />
            </SimpleShowLayout>
        </Show>
    )
}
