import {
    SimpleForm,
    Edit,
    required,
    TextInput,
    NumberInput,
    BooleanInput
} from "react-admin";
import { InputAdornment } from "@material-ui/core";
import { ActionsBack } from "../../../components/ActionsBack";
export const SizeEdit = props => {
    return (
        <Edit
            {...props}
            title={'Edit Size'}
            actions={<ActionsBack/>}
        >
            <SimpleForm
                redirect='list'
                variant='standard'
                margin='dense'
            >
                <TextInput source="name" label="Name" validate={[required()]} />
                <NumberInput source="width" label="Width"  validate={[required()]}/>
                <NumberInput source="height" label="Height"  validate={[required()]}/>
                <NumberInput source="price" label="Price" validate={[required()]} InputProps={{
                    startAdornment: (<InputAdornment position='start'>$</InputAdornment>)
                }} />
                <NumberInput source="price_discount" label="Price with discount" InputProps={{
                    startAdornment: (<InputAdornment position='start'>$</InputAdornment>)
                }} />
                <BooleanInput source="discount_active" label="Discount active"  />
                <BooleanInput source="active" label="Active"  />
            </SimpleForm>
        </Edit>
    );
};
